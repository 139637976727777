const myHTML = `<!DOCTYPE html>
<html>
<head>
  <meta charset='utf-8'>
  <meta name='viewport' content='width=device-width'>
  <title>Politica sulla Privacy</title>
  <style> body { font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;} </style>
</head>
<body>
<strong>Politica sulla Privacy</strong>
            <p>
              🇬🇧 <a href="https://rodriguezrama.com/lockspeed/policy">Privacy Policy</a>
            </p> <p>
              🇪🇸 <a href="https://rodriguezrama.com/lockspeed/policy-es">Política de Privacidad</a>
            </p> <p>
              Sergio Rodríguez Rama ha costruito l'app LockSpeed come
              un'app commerciale. Questo SERVIZIO è fornito da
              Sergio Rodríguez Rama ed è destinato all'uso come
              è.
            </p> <p>
              Se scegli di utilizzare il mio Servizio, accetti di
              le informazioni relative alla presente
              politica.
            </p> <p><strong>Cookies</strong></p> <p>
              I cookie sono file con una piccola quantità di dati che sono
              comunemente usati come identificatori unici anonimi. Questi sono inviati
              al tuo browser dai siti web che visiti e sono
              memorizzati nella memoria interna del dispositivo.
            </p> <p>
              Questo Servizio non utilizza alcun "cookie".
            </p> <p><strong>Service Providers</strong></p> <p>
              LockSpeed utilizza un framework di terze parti <a href="https://www.revenuecat.com/privacy">RevenueCat</a> che raccoglie la cronologia degli acquisti anonimi nell'app degli utenti; Abbonamenti acquistati sul muro di pagamento LockSpeed +.<p>Questi acquisti non sono legati alla tua identità personale.</p>
              </p></div> <p><strong>Modifiche alla presente Politica sulla Privacy</strong></p> <p>
              Posso aggiornare la nostra Informativa sulla privacy da
              di tanto in tanto. Pertanto, si consiglia di rivedere questa pagina
              periodicamente per eventuali modifiche.
            </p> <p>Questa politica è efficace dal 11 Aprile 2024</p> <p><strong>Contattaci</strong></p> <p>
              Se avete domande o suggerimenti sul mio
              Privacy Policy, non esitate a contattarmi all'indirizzo <a href= "mailto:sergio@rodriguezrama.com">sergio@rodriguezrama.com</a>.
              </body>
</html>
  `;

const LockSpeedPolicyIt = () => {
  return (
    <div
      style={{ backgroundColor: "white", padding: "1em", width: "100%" }}
      dangerouslySetInnerHTML={{ __html: myHTML }}
    />
  );
};

export default LockSpeedPolicyIt;
