const myHTML = `<!DOCTYPE html>
<html>
<head>
  <meta charset='utf-8'>
  <meta name='viewport' content='width=device-width'>
  <title>Privacy Policy</title>
  <style> body { font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;} </style>
</head>
<body>
<strong>Privacy Policy</strong> <p>
              Sergio Rodríguez Rama built the Memotico app as
              a Commercial app. This SERVICE is provided by
              Sergio Rodríguez Rama  and is intended for use as
              is.
            </p> <p>
              If you choose to use my Service, then you agree to
              the information in relation to this
              policy.
            </p> <p><strong>Information Collection and Use</strong></p> <p>
              I don't collect any data from you.
            </p> <!----> <p><strong>Log Data</strong></p> <p>
              I don't log any data.
            </p> <p><strong>Cookies</strong></p> <p>
              Cookies are files with a small amount of data that are
              commonly used as anonymous unique identifiers. These are sent
              to your browser from the websites that you visit and are
              stored on your device's internal memory.
            </p> <p>
              This Service does not use any “cookies”.
            </p> <p><strong>Service Providers</strong></p> <p>
              I don't employ any third-party companies or
              individuals.
              </p></div> <p><strong>Changes to This Privacy Policy</strong></p> <p>
              I may update our Privacy Policy from
              time to time. Thus, you are advised to review this page
              periodically for any changes.
            </p> <p>This policy is effective as of 2023-09-16</p> <p><strong>Contact Us</strong></p> <p>
              If you have any questions or suggestions about my
              Privacy Policy, do not hesitate to contact me at <a href= "mailto:sergio@rodriguezrama.com">sergio@rodriguezrama.com</a>.
              </body>
</html>
  `;

const MemoticoPolicy = () => {
  return (
    <div
      style={{ backgroundColor: "white", padding: "1em", width: "100%" }}
      dangerouslySetInnerHTML={{ __html: myHTML }}
    />
  );
};

export default MemoticoPolicy;
