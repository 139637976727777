const myHTML = `<!DOCTYPE html>
<html>
<head>
  <meta charset='utf-8'>
  <meta name='viewport' content='width=device-width'>
  <title>Política de privacidad</title>
  <style> body { font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;} </style>
</head>
<body>
<strong>Política de privacidad</strong> <p>
            Sergio Rodríguez Rama construyó la aplicación Memotico como
            una aplicación comercial. Este servicio es proporcionado por
            Sergio Rodríguez Rama y está destinado a su uso como
            es.
            </p> <p>
              Si decide utilizar mi Servicio, entonces acepta
              la información en relación con este
              política.
            </p> <p><strong>Recopilación y uso de información</strong></p> <p>
              No recojo ningún dato tuyo.
            </p> <!----> <p><strong>Registro de datos</strong></p> <p>
              No registro ningún dato.
            </p> <p><strong>Cookies</strong></p> <p>
              Las cookies son archivos con una pequeña cantidad de datos que son
              comúnmente utilizados como identificadores únicos anónimos. Estos se envían
              a su navegador desde los sitios web que visita y son
              almacenado en la memoria interna del dispositivo.
            </p> <p>
              Este Servicio no utiliza "cookies".
            </p> <p><strong>Proveedores de servicio</strong></p> <p>
              No empleo a terceras empresas o
              individuos.
              </p></div> <p><strong>Cambios en esta Política de privacidad</strong></p> <p>
              Puedo actualizar nuestra Política de Privacidad. Por lo tanto, se recomienda revisar esta página
              periódicamente para cualquier cambio.
            </p> <p>Esta política es efectiva a partir de 2023-09-16</p> <p><strong>Contáctenos</strong></p> <p>
              Si tiene alguna pregunta o sugerencia sobre mi
              Política de privacidad, no dude en contactarme a <a href= "mailto:sergio@rodriguezrama.com">sergio@rodriguezrama.com</a>.
              </body>
</html>
  `;

const MemoticoPolicyEs = () => {
  return (
    <div
      style={{ backgroundColor: "white", padding: "1em", width: "100%" }}
      dangerouslySetInnerHTML={{ __html: myHTML }}
    />
  );
};

export default MemoticoPolicyEs;
