import React from 'react';
import styles from './Header.module.css';

const Header = ({ title, children }) => {
  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.buttons}>
          {children}
        </div>
      </div>
    </header>
  );
};

export default Header;
