const myHTML = `<!DOCTYPE html>
<html>
<head>
  <meta charset='utf-8'>
  <meta name='viewport' content='width=device-width'>
  <title>Política de privacidad</title>
  <style> body { font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;} </style>
</head>
<body>
<strong>Política de privacidad</strong>
            <p>
              🇬🇧 <a href="https://rodriguezrama.com/lockspeed/policy">Privacy Policy</a>
            </p> <p>
              🇮🇹 <a href="https://rodriguezrama.com/lockspeed/policy-it">Politica sulla Privacy</a>
            </p> <p>
            Sergio Rodríguez Rama construyó la aplicación LockSpeed como
            una aplicación comercial. Este servicio es proporcionado por
            Sergio Rodríguez Rama y está destinado a su uso como
            es.
            </p> <p>
              Si decide utilizar mi Servicio, entonces acepta
              la información en relación con este
              política.
            </p> <p><strong>Cookies</strong></p> <p>
              Las cookies son archivos con una pequeña cantidad de datos que son
              comúnmente utilizados como identificadores únicos anónimos. Estos se envían
              a su navegador desde los sitios web que visita y son
              almacenado en la memoria interna del dispositivo.
            </p> <p>
              Este Servicio no utiliza "cookies".
            </p> <p><strong>Proveedores de servicio</strong></p> <p>
              LockSpeed utiliza un framework de terceros <a href="https://www.revenuecat.com/privacy">RevenueCat</a> que recopila el historial de compras anónimas en la aplicación de los usuarios; suscripciones compradas en el muro de pagos LockSpeed +.<p>Estas compras no están vinculadas a su identidad personal.</p>
              </p></div> <p><strong>Cambios en esta Política de privacidad</strong></p> <p>
              Puedo actualizar nuestra Política de Privacidad. Por lo tanto, se recomienda revisar esta página
              periódicamente para cualquier cambio.
            </p> <p>Esta política es efectiva a partir de 11 Abril 2024</p> <p><strong>Contáctenos</strong></p> <p>
              Si tiene alguna pregunta o sugerencia sobre mi
              Política de privacidad, no dude en contactarme a <a href= "mailto:sergio@rodriguezrama.com">sergio@rodriguezrama.com</a>.
              </body>
</html>
  `;

const LockSpeedPolicyEs = () => {
  return (
    <div
      style={{ backgroundColor: "white", padding: "1em", width: "100%" }}
      dangerouslySetInnerHTML={{ __html: myHTML }}
    />
  );
};

export default LockSpeedPolicyEs;
