const myHTML = `<!DOCTYPE html>
<html>
<head>
  <meta charset='utf-8'>
  <meta name='viewport' content='width=device-width'>
  <title>Politica sulla Privacy</title>
  <style> body { font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;} </style>
</head>
<body>
<strong>Politica sulla Privacy</strong> <p>
              Sergio Rodríguez Rama ha costruito l'app Memotico come
              un'app commerciale. Questo SERVIZIO è fornito da
              Sergio Rodríguez Rama ed è destinato all'uso come
              è.
            </p> <p>
              Se scegli di utilizzare il mio Servizio, accetti di
              le informazioni relative alla presente
              politica.
            </p> <p><strong>Raccolta e utilizzo delle informazioni</strong></p> <p>
              Non raccolgo dati da te.
            </p> <!----> <p><strong>Registrare i dati</strong></p> <p>
              Non registro nessun dato.
            </p> <p><strong>Cookies</strong></p> <p>
              I cookie sono file con una piccola quantità di dati che sono
              comunemente usati come identificatori unici anonimi. Questi sono inviati
              al tuo browser dai siti web che visiti e sono
              memorizzati nella memoria interna del dispositivo.
            </p> <p>
              Questo Servizio non utilizza alcun "cookie".
            </p> <p><strong>Service Providers</strong></p> <p>
              Non assumo alcuna società di terze parti o
              individui.
              </p></div> <p><strong>Modifiche alla presente Politica sulla Privacy</strong></p> <p>
              Posso aggiornare la nostra Informativa sulla privacy da
              di tanto in tanto. Pertanto, si consiglia di rivedere questa pagina
              periodicamente per eventuali modifiche.
            </p> <p>Questa politica è efficace dal 2023-09-16</p> <p><strong>Contattaci</strong></p> <p>
              Se avete domande o suggerimenti sul mio
              Privacy Policy, non esitate a contattarmi all'indirizzo <a href= "mailto:sergio@rodriguezrama.com">sergio@rodriguezrama.com</a>.
              </body>
</html>
  `;

const MemoticoPolicyIt = () => {
  return (
    <div
      style={{ backgroundColor: "white", padding: "1em", width: "100%" }}
      dangerouslySetInnerHTML={{ __html: myHTML }}
    />
  );
};

export default MemoticoPolicyIt;
