const ContactSupport = () => {
  return (
    <div style={{ padding: "1em", backgroundColor: "black", width: "100%", color: 'white' }}>
      <h1>Contact</h1>
      <a href="mailto:sergio@rodriguezrama.com">sergio@rodriguezrama.com</a>
    </div>
  );
};

export default ContactSupport;
