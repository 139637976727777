import React from 'react';
import styles from './Main.module.css';
import noriaImage from './noria.jpeg';
import fenceImage from './fence.jpeg';
import containerImage from './container.jpeg';
import buildImage from './build.jpeg';
import Header from './Header';

export const Main = () => (
  <div className={styles.container}>
    <Header title="sergio" />
    ios dev @ <a href="https://apps.apple.com/app/id1094776229" className={styles.link}>teleclinic</a>
    <br/>-<br/>
    <a href="https://apps.apple.com/app/id6483933438" className={styles.link}>zerocam</a> - idea and design by <a href="https://x.com/novikoff">@novikoff</a>
    <br/>-<br/>
    other apps - <a href="https://apps.apple.com/app/id6478423217" className={styles.link}>lockspeed</a> - <a href="https://apps.apple.com/app/id6465680456" className={styles.link}>memotico</a>
</div>
);