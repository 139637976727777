const myHTML = `<!DOCTYPE html>
<html>
<head>
  <meta charset='utf-8'>
  <meta name='viewport' content='width=device-width'>
  <title>Privacy Policy</title>
  <style> body { font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;} </style>
</head>
<body>
<strong>Privacy Policy</strong>
            <p>
              🇪🇸 <a href="https://rodriguezrama.com/lockspeed/policy-es">Política de Privacidad</a>
            </p> <p>
              🇮🇹 <a href="https://rodriguezrama.com/lockspeed/policy-it">Politica sulla Privacy</a>
            </p> <p>
              Sergio Rodríguez Rama built the LockSpeed app as
              a Commercial app. This SERVICE is provided by
              Sergio Rodríguez Rama  and is intended for use as
              is.
            </p> <p>
              If you choose to use my Service, then you agree to
              the information in relation to this
              policy.
            </p> <p><strong>Cookies</strong></p> <p>
              Cookies are files with a small amount of data that are
              commonly used as anonymous unique identifiers. These are sent
              to your browser from the websites that you visit and are
              stored on your device's internal memory.
            </p> <p>
              This Service does not use any “cookies”.
            </p> <p><strong>Service Providers</strong></p> <p>
              LockSpeed uses a third-party framework <a href="https://www.revenuecat.com/privacy">RevenueCat</a> that collects anonymous purchase history in the user app; Subscriptions purchased on the LockSpeed+ payment wall.<p>These purchases are not related to your personal identity.</p>
              </p></div> <p><strong>Changes to This Privacy Policy</strong></p> <p>
              I may update our Privacy Policy from
              time to time. Thus, you are advised to review this page
              periodically for any changes.
            </p> <p>This policy is effective as of 11 April 2024</p> <p><strong>Contact Us</strong></p> <p>
              If you have any questions or suggestions about my
              Privacy Policy, do not hesitate to contact me at <a href= "mailto:sergio@rodriguezrama.com">sergio@rodriguezrama.com</a>.
              </body>
</html>
  `;

const LockSpeedPolicy = () => {
  return (
    <div
      style={{ backgroundColor: "white", padding: "1em", width: "100%" }}
      dangerouslySetInnerHTML={{ __html: myHTML }}
    />
  );
};

export default LockSpeedPolicy;
